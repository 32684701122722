import React from "react"

const Logo = () => (
  <div></div>
)

Logo.propTypes = {
}

Logo.defaultProps = {
}

export default Logo
