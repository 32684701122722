import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import Logo from './Logo'

const Container = styled.div`
  display: flex;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Header = ({ siteTitle }) => (
  <header>
    <Container>
      <Logo />
      <h1>
        <Link
          to="/"
        >
          {siteTitle}
        </Link>
      </h1>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
