import { HOME, WEARE } from "canvas/SceneEnum"
import { TimelineDispatchContext, TimelineStateContext } from "components/Backdrop"
import { Link } from "gatsby"
import React, { useEffect } from "react"
import styled, {keyframes} from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/Button"

import Header from "../components/header"

const BaseAnimation = styled.div`  
  animation-duration: ${props => props.duration};  
  animation-timing-function: ${props => props.timingFunction};      
  animation-delay: ${props => props.delay};  
  animation-iteration-count: ${props => props.iterationCount};  
  animation-direction: ${props => props.direction}; 
  animation-fill-mode: ${props => props.fillMode};  
  animation-play-state:  ${props => props.playState};  
  display: ${props => props.display};
`;

const FadeInAnimation = keyframes`  
  from { opacity: 0; }
  to { opacity: 1; }
`;

const FadeIn = styled(BaseAnimation)`
  animation-name: ${FadeInAnimation};
`;

const IntroContainer = styled.div`
  width: 23vw;
  align-self: flex-end;
  margin: 2rem;
  
  
  
  
  h1 {
  background: url("dark_paper.png");
    margin-bottom: 1rem;
    //box-shadow: 4px 4px 0px 0px #FFFFFF;
    //border: 1px solid white;
    position: relative;
  }
   
   transition: opacity 1s;
  opacity: ${props => props.animateContent ? "23vw" : "0"};
  //animation-name: ${FadeInAnimation};
`
const Intro = styled.div`
  background: url("white_paper.png");
  padding: 2rem;
  color: black;
  border: 1px solid rgba(10, 10, 10, 0.98);
  position: relative;
  box-shadow: 4px 4px 0px 0px rgba(10,10,10, 0.8);
  
  p {
    margin-bottom: 1.5rem;
  }
}
`
const PAGE = HOME

const IndexPage = () => {
  const startScene = React.useContext(TimelineDispatchContext)

  useEffect(() => {
    console.log("Use effect home")
    startScene(PAGE)
  }, []) // Make sure the effect runs only once

  const timeline = React.useContext(TimelineStateContext)
  const animateContent = typeof timeline === 'object' && timeline.currentScene === PAGE && !timeline.transitioning

  return (
    <Layout>
      <Header siteTitle='Great Company' animateContent={animateContent}/>
      <IntroContainer animateContent={animateContent}>
        <h1>Great Company</h1>
        <Intro>
          <SEO title="Intro" />
          <p><strong>We produce high-end digital apps and experiences</strong> that integrate with the whole. We’re pretty damn proud of every line of code and pixel we deliver.</p>
          <p><strong>Our process</strong> is always evolving and <strong>empowers us to deliver top-notch digital products.</strong> </p>
          <p><strong>Give us a shout</strong> or even better, come by for a drink, we’re always in the mood <strong>for a chat with great company.</strong></p>
          <Link to="/weare"><Button label='Get in touch' route='/contact'/></Link>
        </Intro>
      </IntroContainer>
    </Layout>
  )
}

export default IndexPage
