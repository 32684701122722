import React from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'

const Main = styled.button`
  position: relative;
  background: rgba(240, 240, 240, 0.99);
  background: url("white_paper.png");
  border: 1px solid rgba(10,10,10, 0.95);
  padding: 0.9rem;
  font-size: 0.9rem;
  color: black;
  box-shadow: 4px 4px 0px 0px rgba(10,10,10, 0.95);
  font-weight: 900;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;
  left: 0;
  top: 0;
  
  z-index: 20;

  &:hover {
    left: 4px;
    top: 4px;
    box-shadow: 0 0 0 0 rgba(10,10,10, 0.95);
  }
`

const Button = ({label, route, ...props}) => {
 return <Main {...props}>{label}</Main>
}

Button.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  route: PropTypes.string,
}

Button.defaultProps = {}

export default Button
